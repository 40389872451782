import { Box, ClickAwayListener, Divider, InputAdornment, Popper, TextField, styled } from "@mui/material";
import { SearchIcon } from "packages/web/src/assets/icons/global";
import { Fragment, useEffect, useRef, useState } from "react";
import CustomTypography from "../customTypography";
import { HistoryIcon } from "packages/web/src/assets/icons/global/HistoryIcon";
import ClearIcon from '@mui/icons-material/Clear';
import { MemberIcon } from "packages/web/src/assets/icons/MemberIcon";
import { GroupIcon } from "../../../assets/icons/GroupIcon";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { NavigateToAsset, NavigateToPortfolio } from "packages/web/src/routes/navigationMethods";
import { useLocation, useNavigate } from "react-router-dom";

export default function GlobalSearchBar() {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<{id: SharedConstants.GlobalSearchFilter, text: string} | undefined>(undefined);

  const globalSearchQuery = useAppSelector(SharedRedux.Portfolios.Selectors.selectGlobalSearchQuery);
  const globalSearchResults = useAppSelector(SharedRedux.Portfolios.Selectors.selectGlobalSearchResults);

  const dispatch = useAppDispatch();

  const id = showOptions ? 'simple-popper' : undefined;

  const searchInputRef = useRef<HTMLElement>();
  const searchInputContainerRef = useRef<HTMLElement>();

  const onSearchOpen = (event: React.MouseEvent<HTMLElement>, source: string) => {    
    if (event.currentTarget && !showOptions) {
      setShowOptions(true);
    }
  };

  const onSearch = (val: string) => {
    setSearchTerm(val);
    dispatch(SharedRedux.Portfolios.Actions.globalSearch({Term: val, Filter: searchFilter?.id || SharedConstants.GlobalSearchFilter.ALL}));
  }
  
  const selectSearchFilter = (option: {id: SharedConstants.GlobalSearchFilter, text: string}) => {
    setSearchFilter(option);
    dispatch(SharedRedux.Portfolios.Actions.globalSearch({Term: '', Filter: option.id}));
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (searchInputContainerRef?.current?.contains(event.target as Node) === false) {
      setShowOptions(false);
    }
  };

  const closeSearchAndClear = () => {
    setShowOptions(false);
    clearSearchFilter();
    onSearch('');
  }

  const onClearClick = () => {
    setTimeout(() => {
      onSearch('');
      if (searchInputRef?.current) {
        searchInputRef?.current.focus();
      }
    });
  }

  const clearSearchFilter = () => {
    selectSearchFilter({id: SharedConstants.GlobalSearchFilter.ALL, text: 'All'});
  }

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const selectAsset = (item: SharedConstants.AssetSearchItem) => {
    if (item.AssetTypeID > 0 && item.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID: item.AssetTypeID, AMID: item.AMID, pathname: location.pathname, isFromGlobalSearch: true});
      closeSearchAndClear();
    }
  };

  const selectPortfolio = (portfolio: SharedConstants.Portfolio) => {
    if (portfolio.Id > 0) {
      var activePortfolio = { CCID: portfolio.CCID, FamilyId: portfolio.FamilyId, PFID: portfolio.Id };
      NavigateToPortfolio({navigate, activePortfolio, pathname: location.pathname, dispatch, shouldDispatchActivePortfolios: true});
      closeSearchAndClear();
    }
  };

  const selectFamily = (item: SharedConstants.Family) => {

  };

  return (
    <Box ref={searchInputContainerRef}>
      <SearchInput
        autoComplete="off"
        inputRef={searchInputRef}
        aria-describedby={id}
        sx={{
          width: { xs: "280px", md: "300px", lg: showOptions ? '500px' : '400px',  xl: showOptions ? '700px' : '400px' },
          "& .MuiInputBase-root": {
            height: 48,
            padding: 0,
            margin: 0
          },
          // ":hover": {backgroundColor:'#F5F5F6'}
        }}
        onClick={(e) => onSearchOpen(e, 'input')}
        onChange={(e) => onSearch(e.target.value)}
        value={searchTerm}
        placeholder="Search assets or portfolios"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{margin:'0px'}}>
              <SearchIcon />
              {
                searchFilter && searchFilter.id != SharedConstants.GlobalSearchFilter.ALL &&
                <SelectedBox>
                  <CustomTypography variant="Regular16" sx={{ paddingLeft: '8px', paddingRight: '8px' }}>{searchFilter.text}</CustomTypography>
                  <ClearIcon fontSize="small" sx={{ color: '#000000', ":hover": { background: '#F5F5F6', borderRadius: '4px' } }} 
                              onClick={clearSearchFilter} />
                </SelectedBox>
              }
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Box sx={{ display: 'flex', alignItems: 'center' }} >
                {searchTerm.length > 0 && showOptions &&
                  <Fragment>
                    <CustomTypography variant="Medium14" sx={{ marginRight: '10px', cursor: 'pointer' }} onClick={onClearClick}>clear</CustomTypography>
                    <span>|</span>
                  </Fragment>
                }
                {id ===  'simple-popper'  && <ClearIcon fontSize="small" 
                                                        sx={{ marginLeft: '5px',marginRight: '14px', cursor: 'pointer' }} 
                                                        onClick={closeSearchAndClear}
                                                           />}
              </Box>
            </InputAdornment>
          )
        }}
      />
      <ClickAwayListener onClickAway={handleClose}>
        <OptionMenu sx={{ width: { xs: "280px", md: "300px", lg: '500px', xl: '700px' }, padding: { xs: "6px 8px", lg: '8px 16px' }, }} id={id} open={showOptions} anchorEl={searchInputContainerRef?.current}>
            <Paper sx={{maxHeight: '400px', overflowY: 'scroll', padding: '20px 0px'}}>
              {
                (!globalSearchQuery || !globalSearchQuery.Term || globalSearchQuery.Term.trim().length == 0)
                ?
                <Box sx={{ width: '100%' }}>
                  <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' }, padding: { xs: "0px 16px 12px 16px", lg: "0px 24px 12px 24px" } }}>I'm looking for an</CustomTypography>
                  <Box sx={{ display: 'flex', cursor: 'pointer', margin: '0px 0px 12px 24px' }}>
                    {
                      [{id: SharedConstants.GlobalSearchFilter.ASSET, text: "Asset"}, {id: SharedConstants.GlobalSearchFilter.PORTFOLIO, text: "Portfolio"}, {id: SharedConstants.GlobalSearchFilter.FAMILY, text: "Family"}].map((item, index) => {
                        return (
                          <OptionBox sx={{ padding: { xs: "6px 8px", lg: '8px 16px' }}} key={`item ${item.id}`} onClick={() => selectSearchFilter(item)}>{item.text}</OptionBox>
                        )
                      })
                    }
                  </Box>
                  {/* <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' }, padding: { xs: "0px 16px 12px 16px", lg: "0px 24px 12px 24px" } }}>Recent searches</CustomTypography>
                  {initialData.map((recent, index) => (
                    <RecentOption
                      key={index}
                      onClick={() => handleSelect(recent)}
                      onMouseEnter={() => { setHovered(true); setCurrentHovered(index) }}
                      onMouseLeave={() => setHovered(false)}
                    >
                      <Box sx={{ display: 'flex', width: '100%' }}>
                        <HistoryIcon />
                        <CustomTypography variant='Regular18' sx={{ color: { xs: '#64677A' }, paddingLeft: '12px' }}>{recent.label}</CustomTypography>
                      </Box>
                      {hoverd && currentHovered === index &&
                        < Box sx={{ width: 'fit-content', transition: '1s liner ease-in-out' }}>
                          <ClearIcon fontSize="small" />
                        </Box>
                      }
                    </RecentOption>
                  ))
                  } */}
                </Box>
              :
              <>
              {
                globalSearchResults && globalSearchResults.Assets && globalSearchResults.Assets.length > 0 &&
                (
                  <Box sx={{marginBottom: '40px'}}>
                  <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' }, padding: { xs: "0px 16px", lg: '0px 24px' } }}>{'Assets'}</CustomTypography>
                  {(globalSearchResults?.Assets || []).map((option: SharedConstants.AssetSearchItem, index: number) => {
                    return (
                      <Fragment key={index}>
                        <OptionContainer key={index} onClick={() => selectAsset(option)} sx={{cursor: 'pointer'}}>
                          <Box>
                            <CustomTypography variant='Regular18'> {option.Name}</CustomTypography>
                            {/* <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}> {option.AssetTypeName}</CustomTypography> */}
                          </Box>
                          {
                            option.AssetTypeName &&
                            <Box sx={{ background: '#F9F9F9', padding: '3px 12px 2px 12px', border: '1px solid #EBECF2', borderRadius: '16px' }}>
                              <CustomTypography sx={ {color: '#64677A', fontSize: '12px'}}>
                              {option.AssetTypeName}
                              </CustomTypography>
                            </Box>
                          }
                        </OptionContainer>
                        <OptionDivider light />
                      </Fragment>
                    )
                  })}
                  </Box>
                )
              }
              {
                globalSearchResults && globalSearchResults.Portfolio && globalSearchResults.Portfolio.length > 0 &&
                (
                  <Box sx={{ marginBottom: '15px',  }}>
                  <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' }, padding: { xs: "0px 16px", lg: '0px 24px' } }}>{'Portfolio'}</CustomTypography>
                  {(globalSearchResults?.Portfolio || []).map((option, index: number) => {
                    return (
                      <Fragment key={index}>
                        <OptionContainer key={index} onClick={() => selectPortfolio(option)} sx={{cursor: 'pointer'}}>
                          <Box sx={{ display: 'flex', height: '40px', alignItems: 'center', width: '100%', justifyContent: 'space-between', gap: '10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {option.IsGroup ? <GroupIcon stroke={'#64677a'}/> : <MemberIcon />}
                              <CustomTypography variant='Regular18' sx={{ paddingLeft: '12px' }}>{option.PFullName || option.PName}</CustomTypography>
                            </Box>
                            {
                              option.FamilyName &&
                              <>
                              {/* <Box sx={{marginLeft: 1, marginRight: 1, color: '#64677A'}}>•</Box> */}
                              <CustomTypography variant="Regular18" sx={{ color: '#64677A' }}> {option.FamilyName + ' Family'}</CustomTypography>
                              </>
                            }
                          </Box>
                        </OptionContainer>
                        <OptionDivider light />
                      </Fragment>
                    )
                  })}
                  </Box>
                )
              }
              {
                globalSearchResults && globalSearchResults.Family && globalSearchResults.Family.length > 0 &&
                (
                  <Box sx={{ marginBottom: '15px' }}>
                  <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A' }, padding: { xs: "0px 16px", lg: '0px 24px' } }}>{'Family'}</CustomTypography>
                  {(globalSearchResults?.Family || []).map((option, index: number) => {
                    return (
                      <Fragment key={index}>
                        <OptionContainer key={index} onClick={() => selectFamily(option)} sx={{cursor: 'pointer'}}>
                          <Box sx={{ padding: '8px 0px', width: '100%' }}>
                            <CustomTypography variant='Regular18'> {option.FamilyName}</CustomTypography>
                            {/* <CustomTypography variant='Regular16' sx={{ color: { xs: '#64677A' } }}> {option.subtitle}</CustomTypography> */}
                          </Box>
                        </OptionContainer>
                        <OptionDivider light />
                      </Fragment>
                    )
                  })}
                  </Box>
                )
              }
              </>
              }
            </Paper>
        </OptionMenu >
      </ClickAwayListener>
    </Box >
  )
}

const SearchInput = styled(TextField)({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    }
  }
})

const OptionMenu = styled(Popper)({
  paddingTop: '8px',
})

const Paper = styled(Box)({
  background: '#FFFFFF',
  boxShadow: '0px 4px 6px #00000014',
  border: '1px solid #D8DAE5',
  borderRadius: '4px',
  padding: '24px 0px'
})

const OptionDivider = styled(Divider)({
  backgroundColor: '#EBECF2',
  alignSelf: "center",
  width: '92%',
  margin: 'auto',
  '&:last-child': {
    display: 'none'
  }
})

const OptionContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '9px 24px 8px 24px',
  boxSizing: 'border-box',
  ':hover': { background: '#F9F9F9' }
})

const OptionBox = styled(Box)({
  display: 'flex',
  background: '#F9F9F9',
  width: 'fit-content',
  border: '1px solid #EBECF2',
  bordeRadius: '4px',
  marginRight: '10px',
  borderRadius: "4px",
  ':hover': {
    background: "#EBECF2",
    border: '1px solid #EBECF2'
  }
})

const RecentOption = styled(Box)({
  padding: '0px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '40px',
  alignItems: 'center',
  boxSizing: 'border-box',
  ':hover': {
    background: '#F9F9F9'
  }
})

const SelectedBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  background: '#D8DAE5',
  boxSizing: "border-box",
  border: '1px solid #D8DAE5',
  padding: '6px 8px 5px 0px',
  cursor: 'pointer'
})
