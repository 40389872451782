import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { MPrUrlParams } from "../classes/MPrUrlParams";
import { Location, NavigateFunction, createSearchParams } from "react-router-dom";
import { Dispatch } from "react";
import { WebRedux } from "..";
import { AuthPage } from "../constants/login";

//#region Navigate to Portfolio
export interface NavigateToPortfolioProps {
    navigate: NavigateFunction;
    activePortfolio?: SharedRedux.Portfolios.SetActivePortfolioPayload;
    pathname?: string;
    dashboardTab?: SharedConstants.DashboardTabs;
    screen?: SharedConstants.ScreenEnum;
    shouldDispatchActivePortfolios?: boolean;
    dispatch?: Dispatch<any>
}

export const NavigateToPortfolio = ({navigate, activePortfolio, pathname, dashboardTab, screen, shouldDispatchActivePortfolios, dispatch}: NavigateToPortfolioProps) => {
    console.log('selectPortfolio2', activePortfolio);
    if (!activePortfolio) return;

    var urlParams = new MPrUrlParams({DatabaseID: activePortfolio.CCID, FamilyID: activePortfolio.FamilyId, PortfolioID: activePortfolio.PFID, Screen: screen || SharedConstants.ScreenEnum.L1});

    navigateBasedOnPathnameDashboardTabUrlParams({navigate, urlParams, dashboardTab, pathname});

    if (shouldDispatchActivePortfolios === true && dispatch) {
        // dispatch(SharedRedux.Portfolios.Actions.setActivePortfolio(activePortfolio));
    }
}

//#region Helper

export const getPathnameFromDashboardTab = (dashboardTab: SharedConstants.DashboardTabs) => {
    switch (dashboardTab) {
        case SharedConstants.DashboardTabs.TODAY:
            return '/portfolio/today'
        case SharedConstants.DashboardTabs.HOLDING:
            return '/portfolio/holding'
        case SharedConstants.DashboardTabs.PERFORMANCE:
            return '/portfolio/performance'
    }

    return undefined;
}

export interface NavigateBasedOnPathnameDashboardTabUrlParamsProps {
    navigate: NavigateFunction;
    urlParams: MPrUrlParams;
    pathname?: string;
    dashboardTab?: SharedConstants.DashboardTabs;
}

export const navigateBasedOnPathnameDashboardTabUrlParams = ({pathname, dashboardTab, urlParams, navigate}: NavigateBasedOnPathnameDashboardTabUrlParamsProps) => {
    if (!pathname) {
        if (!dashboardTab) dashboardTab = SharedConstants.DashboardTabs.TODAY;
        pathname = getPathnameFromDashboardTab(dashboardTab);
    }

    if (!pathname) return;

    navigate({
      pathname: pathname,
      search: createSearchParams({
        p: urlParams.getUrlString()
      }).toString()
    });
}

//#endregion

//#endregion

//#region Navigate to Asset Allocation Categ
export interface NavigateToAssetAllocationCategProps {
    navigate: NavigateFunction;
    pathname?: string;
    dashboardTab?: SharedConstants.DashboardTabs;
    activeScreenIDs?: SharedRedux.Portfolios.ActiveScreenPayload;
    assetAllocationCategID: SharedConstants.AssetAllocationCategID
}

export const NavigateToAssetAllocationCateg = ({navigate, activeScreenIDs, assetAllocationCategID, pathname, dashboardTab}: NavigateToAssetAllocationCategProps) => {
    if (!activeScreenIDs) return;

    var urlParams = new MPrUrlParams({
        Screen: SharedConstants.ScreenEnum.L1_AA,
        DatabaseID: activeScreenIDs.DatabaseID,
        FamilyID: activeScreenIDs.FamilyID,
        PortfolioID: activeScreenIDs.PortfolioID,
        AssetAllocationCategID: assetAllocationCategID
    });

    navigateBasedOnPathnameDashboardTabUrlParams({navigate, urlParams, dashboardTab, pathname});
}

//#endregion

//#region Navigate to Asset Type
export interface NavigateToAssetTypeProps {
    navigate: NavigateFunction;
    pathname?: string;
    dashboardTab?: SharedConstants.DashboardTabs;
    activeScreenIDs?: SharedRedux.Portfolios.ActiveScreenPayload;
    assetTypeID: SharedConstants.AssetTypeEnum;
    removeAssetAllocationCategID?: boolean;
}

export const NavigateToAssetType = ({navigate, activeScreenIDs, assetTypeID, pathname, dashboardTab, removeAssetAllocationCategID = false}: NavigateToAssetTypeProps) => {
    if (!activeScreenIDs) return;

    var urlParams = new MPrUrlParams({
        Screen: SharedConstants.ScreenEnum.L2,
        DatabaseID: activeScreenIDs.DatabaseID,
        FamilyID: activeScreenIDs.FamilyID,
        PortfolioID: activeScreenIDs.PortfolioID,
        AssetType: assetTypeID,
        AssetAllocationCategID: removeAssetAllocationCategID ? undefined : activeScreenIDs.AssetAllocationCategID
    });

    navigateBasedOnPathnameDashboardTabUrlParams({navigate, urlParams, dashboardTab, pathname});
}

//#endregion

//#region Navigate to Equity Exposure
export interface NavigateToEquityExposureProps {
    navigate: NavigateFunction;
    pathname?: string;
    activeScreenIDs?: SharedRedux.Portfolios.ActiveScreenPayload;
}

export const NavigateToEquityExposure = ({navigate, activeScreenIDs, pathname}: NavigateToEquityExposureProps) => {
    if (!activeScreenIDs) return;

    var urlParams = new MPrUrlParams({
        Screen: SharedConstants.ScreenEnum.L2_AllStocks,
        DatabaseID: activeScreenIDs.DatabaseID,
        FamilyID: activeScreenIDs.FamilyID,
        PortfolioID: activeScreenIDs.PortfolioID,
    });

    navigateBasedOnPathnameDashboardTabUrlParams({navigate, urlParams, dashboardTab: SharedConstants.DashboardTabs.HOLDING});
}

//#endregion

//#region Navigate to Asset

export interface NavigateToAssetProps {
    navigate: NavigateFunction;
    pathname?: string;
    dashboardTab?: SharedConstants.DashboardTabs;
    activeScreenIDs?: SharedRedux.Portfolios.ActiveScreenPayload;
    assetTypeID: SharedConstants.AssetTypeEnum,
    AMID: number,
    SID?: number,
    isFromGlobalSearch?: boolean
}

export const NavigateToAsset = ({navigate, activeScreenIDs, assetTypeID, AMID, SID, pathname, dashboardTab, isFromGlobalSearch}: NavigateToAssetProps) => {
    if (!activeScreenIDs) return;

    var urlParams = new MPrUrlParams({
        Screen: assetTypeID > SharedConstants.AssetTypeEnum.EQ && SID && SID > 0 ? SharedConstants.ScreenEnum.L4_RefNo : SharedConstants.ScreenEnum.L4,
        DatabaseID: activeScreenIDs.DatabaseID,
        FamilyID: activeScreenIDs.FamilyID,
        PortfolioID: activeScreenIDs.PortfolioID,
        AssetType: assetTypeID,
        AMID,
        SID,
        SectorID: isFromGlobalSearch ? undefined : activeScreenIDs.SectorID,
        MarketCapID: isFromGlobalSearch ? undefined : activeScreenIDs.MarketCapID,
        AssetAllocationCategID: isFromGlobalSearch ? undefined : activeScreenIDs.AssetAllocationCategID,
        SEBICategoryID: isFromGlobalSearch ? undefined : activeScreenIDs.SEBICategoryID,
        SEBISubCategoryID: isFromGlobalSearch ? undefined : activeScreenIDs.SEBISubCategoryID
    });

    console.log('NavigateToAsset', urlParams)

    navigateBasedOnPathnameDashboardTabUrlParams({navigate, urlParams, dashboardTab, pathname});
}

//#endregion

//#region Navigate to L3

export interface NavigateToL3Props {
    navigate: NavigateFunction;
    pathname?: string;
    dashboardTab?: SharedConstants.DashboardTabs;
    activeScreenIDs?: SharedRedux.Portfolios.ActiveScreenPayload;
    assetTypeID: SharedConstants.AssetTypeEnum,
    SectorID?: number,
    MarketCapID?: number,
    SEBICategoryID?: number,
    SEBISubCategoryID?: number,
    L3Mode: SharedConstants.HoldingsMode
}

export const NavigateToL3 = ({navigate, activeScreenIDs, assetTypeID, L3Mode, SectorID, MarketCapID, SEBICategoryID, SEBISubCategoryID, pathname, dashboardTab}: NavigateToL3Props) => {
    if (!activeScreenIDs) return;

    console.log(SectorID, 'SectorID');

    var urlParams = new MPrUrlParams({
        Screen: SharedConstants.ScreenEnum.L3,
        DatabaseID: activeScreenIDs.DatabaseID,
        FamilyID: activeScreenIDs.FamilyID,
        PortfolioID: activeScreenIDs.PortfolioID,
        AssetType: assetTypeID,
        SectorID,
        MarketCapID,
        SEBICategoryID,
        SEBISubCategoryID,
        L3Mode
    });

    navigateBasedOnPathnameDashboardTabUrlParams({navigate, urlParams, dashboardTab, pathname});
}

//#endregion

//#region Navigate to L2 Category

export interface NavigateToL3CategoryProps {
    navigate: NavigateFunction;
    pathname?: string;
    dashboardTab?: SharedConstants.DashboardTabs;
    activeScreenIDs?: SharedRedux.Portfolios.ActiveScreenPayload;
    assetTypeID: SharedConstants.AssetTypeEnum,
    SectorID?: number,
    MarketCapID?: number,
    SEBICategoryID?: number,
    SEBISubCategoryID?: number,
    L3Mode: SharedConstants.HoldingsMode
}

export const NavigateToL3Category = ({navigate, activeScreenIDs, assetTypeID, L3Mode, SectorID, MarketCapID, SEBICategoryID, SEBISubCategoryID, pathname, dashboardTab}: NavigateToL3CategoryProps) => {
    if (!activeScreenIDs) return;

    console.log(SectorID, 'SectorID');

    var urlParams = new MPrUrlParams({
        Screen: SharedConstants.ScreenEnum.L3_Category,
        DatabaseID: activeScreenIDs.DatabaseID,
        FamilyID: activeScreenIDs.FamilyID,
        PortfolioID: activeScreenIDs.PortfolioID,
        AssetType: assetTypeID,
        SectorID,
        MarketCapID,
        SEBICategoryID,
        SEBISubCategoryID,
        L3Mode
    });

    navigateBasedOnPathnameDashboardTabUrlParams({navigate, urlParams, dashboardTab, pathname});
}

//#endregion

//#region Navigate to Login

export const NavigateOnLogout = ({navigate, dispatch, IsUserAction}: {navigate: NavigateFunction, dispatch: Dispatch<any>, IsUserAction?: boolean}) => {
    dispatch(SharedRedux.Login.Actions.logout({IsUserAction: IsUserAction === true}));
    dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.LoginForm }))
    navigate('/');
}

//#endregion

//#region Helpers

export const isDashboardNavigationRoute = (portfolioSubPath: string) => {
    return ['today','holding','performance'].includes(portfolioSubPath);
}

//#endregion

//#region Navigate between Tabs

export const NavigateToTab = ({tab, navigate, location} : {tab: SharedConstants.DashboardTabs, navigate: NavigateFunction, location: Location}) => {
    var pathname = getPathnameFromDashboardTab(tab);
    navigate({ pathname: pathname, search: location.search });
}

//#endregion